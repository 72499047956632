<template>
  <!--begin::Calendar-->
  <div class="calendar-template" v-if="getPermission('calendar:view')">
    <DetailTemplate customSecondClass="pt-0">
      <template v-slot:header-title>
        <h3 class="custom-header-blue-text font-size-28 text-uppercase mt-2">
          Calendar
        </h3>
      </template>
      <template v-slot:header-action>
        <div class="d-flex align-right justify-content-end mt-2">
          <p class="m-0">
            <v-icon large class="mx-2" color="cyan darken-4">mdi-record</v-icon>
            <span class="font-size-18 cyan--text text--darken-4">Task</span>
          </p>
          <p class="m-0">
            <v-icon large class="mx-2" color="orange darken-2"
              >mdi-record</v-icon
            >
            <span class="font-size-18 orange--text text--darken-2"
              >Meeting</span
            >
          </p>
        </div>
        <v-btn-toggle v-if="false">
          <v-btn class="custom-bold-button my-2" color="cyan white--text">
            Calendar
          </v-btn>
          <!--  <v-btn
            class="custom-bold-button my-2"
            v-on:click="$router.push(getDefaultRoute('calendar.resource'))"
          >
            Resource
          </v-btn>-->
        </v-btn-toggle>
      </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <div v-if="eventLoading" class="block-ui">
            <v-progress-circular
              indeterminate
              :size="100"
              :width="8"
              color="cyan"
            ></v-progress-circular>
          </div>
          <v-row class="fill-height custom-border-top">
            <v-col md="12">
              <v-sheet height="64">
                <v-toolbar class="content-px-0" flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Today
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-select
                    :disabled="eventLoading"
                    class="mx-2"
                    style="max-width: 380px"
                    multiple
                    color="cyan"
                    item-color="cyan"
                    v-model="users"
                    :items="userList"
                    hide-details
                    dense
                    flat
                    solo
                    placeholder="Select User"
                    v-on:change="filterEvents()"
                  ></v-select>
                  <v-btn
                    :disabled="eventLoading"
                    depressed
                    tile
                    :outlined="task_type != 'all'"
                    color="cyan white--text"
                    class="mx-2"
                    v-on:click="
                      task_type = 'all';
                      getCalendarEvents();
                    "
                    >All</v-btn
                  >
                  <v-btn
                    v-if="false"
                    :disabled="eventLoading"
                    depressed
                    tile
                    :outlined="task_type != 'personal'"
                    color="cyan white--text"
                    class="mx-2"
                    v-on:click="
                      task_type = 'personal';
                      getCalendarEvents();
                    "
                    >Personal</v-btn
                  >
                  <v-btn
                    v-if="false"
                    :disabled="eventLoading"
                    depressed
                    tile
                    :outlined="task_type != 'project'"
                    color="cyan white--text"
                    class="mx-2"
                    v-on:click="
                      task_type = 'project';
                      getCalendarEvents();
                    "
                    >Project</v-btn
                  >
                  <v-btn
                    :disabled="eventLoading"
                    depressed
                    tile
                    :outlined="task_type != 'task'"
                    color="cyan white--text"
                    class="mx-2"
                    v-on:click="
                      task_type = 'task';
                      getCalendarEvents();
                    "
                    >Task</v-btn
                  >
                  <v-btn
                    v-if="false"
                    :disabled="eventLoading"
                    depressed
                    tile
                    :outlined="task_type != 'meeting'"
                    color="cyan white--text"
                    class="mx-2"
                    v-on:click="
                      task_type = 'meeting';
                      getCalendarEvents();
                    "
                    >Meeting</v-btn
                  >
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        class="ml-2"
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="cyan"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                >
                  <template v-slot:day-label="{ day, date }">
                    <div
                      class="py-2 cursor-default"
                      v-on:drop="dropEngineer(date)"
                      v-on:dragover="allowDrop"
                    >
                      <span>{{ day }}</span>
                    </div>
                  </template>
                </v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  max-width="600px"
                  rounded="0"
                >
                  <v-card
                    color="white"
                    min-width="350px"
                    max-width="600px"
                    flat
                  >
                    <v-toolbar :color="selectedEvent.color" dark>
                      <table width="100%">
                        <tr>
                          <td
                            class="font-size-24 font-weight-600"
                            align="left"
                            width="50%"
                          >
                            <span v-if="selectedEvent.is_meeting">
                              Meeting
                            </span>
                            <span v-if="selectedEvent.is_task"> Task </span>
                          </td>
                          <td
                            class="font-size-24 font-weight-600"
                            width="50%"
                            align="right"
                          >
                            <v-btn icon v-on:click="selectedOpen = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-toolbar>
                    <v-card-text>
                      <h2 class="custom-header-blue-text">
                        {{ selectedEvent.name }}
                      </h2>
                      <p class="font-size-16">
                        {{ formatDate(selectedEvent.start) }} -
                        {{ formatDate(selectedEvent.end) }}
                      </p>
                      <template v-if="selectedEvent.is_meeting">
                        <table width="100%" style="table-layout: fixed">
                          <tr v-if="selectedEvent.u_name">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Member
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              <router-link
                                :to="{
                                  name: 'admin.user.detail',
                                  params: { id: selectedEvent.u_id },
                                }"
                              >
                                {{ selectedEvent.u_name }}
                                <v-icon small color="blue darken-4"
                                  >mdi-link-variant</v-icon
                                >
                              </router-link>
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.m_c_name">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Customer
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              <router-link
                                :to="{
                                  name: 'admin.customer.detail',
                                  params: { id: selectedEvent.m_c_id },
                                }"
                              >
                                {{ selectedEvent.m_c_name }}
                                <v-icon small color="blue darken-4"
                                  >mdi-link-variant</v-icon
                                >
                              </router-link>
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.m_p_name">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Project
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              <router-link
                                :to="{
                                  name: 'admin.project.detail',
                                  params: { id: selectedEvent.m_p_id },
                                }"
                              >
                                {{ selectedEvent.m_p_name }}
                                <v-icon small color="blue darken-4"
                                  >mdi-link-variant</v-icon
                                >
                              </router-link>
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Reference
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ selectedEvent.m_reference }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Location
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ selectedEvent.m_location }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Website URL
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ selectedEvent.m_url }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Meeting URL
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ selectedEvent.m_meeting_url }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Remarks
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              <div v-html="selectedEvent.m_remark"></div>
                            </td>
                          </tr>
                        </table>
                      </template>
                      <template v-if="selectedEvent.is_task">
                        <table width="100%" style="table-layout: fixed">
                          <tr v-if="selectedEvent.u_name">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Member
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              <router-link
                                :to="{
                                  name: 'admin.user.detail',
                                  params: { id: selectedEvent.u_id },
                                }"
                              >
                                {{ selectedEvent.u_name }}
                                <v-icon small color="blue darken-4"
                                  >mdi-link-variant</v-icon
                                >
                              </router-link>
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_c_name">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Customer
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              <router-link
                                :to="{
                                  name: 'admin.customer.detail',
                                  params: { id: selectedEvent.t_c_id },
                                }"
                              >
                                {{ selectedEvent.t_c_name }}
                                <v-icon small color="blue darken-4"
                                  >mdi-link-variant</v-icon
                                >
                              </router-link>
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_p_name">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Project
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              <router-link
                                :to="{
                                  name: 'admin.project.detail',
                                  params: { id: selectedEvent.t_p_id },
                                }"
                              >
                                {{ selectedEvent.t_p_name }}
                                <v-icon small color="blue darken-4"
                                  >mdi-link-variant</v-icon
                                >
                              </router-link>
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Task #
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ selectedEvent.t_barcode }}
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_reference">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Reference
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ selectedEvent.t_reference }}
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_subject">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Subject
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ selectedEvent.t_subject }}
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_p_start_date">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Planned Start Date
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ formatDate(selectedEvent.t_p_start_date) }}
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_p_due_date">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Planned Due Date
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ formatDate(selectedEvent.t_p_due_date) }}
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_p_duration">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Planned Duration
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ selectedEvent.t_p_duration }}
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_a_start_date">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Actual Start Date
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ formatDate(selectedEvent.t_a_start_date) }}
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_a_finish_date">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Actual Finish Date
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ formatDate(selectedEvent.t_a_finish_date) }}
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_a_duration">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Actual Duration
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ selectedEvent.t_a_duration }}
                            </td>
                          </tr>
                          <tr v-if="selectedEvent.t_task_cost">
                            <td
                              width="30%"
                              class="py-1 font-size-16 font-weight-600"
                            >
                              Task Cost
                            </td>
                            <td width="70%" class="py-1 font-size-16">
                              {{ formatMoney(selectedEvent.t_task_cost) }}
                            </td>
                          </tr>
                        </table>
                        <template
                          v-for="(row, index) in selectedEvent.checklists"
                        >
                          <p
                            :key="index"
                            class="m-0 py-1 font-size-16 teal--text"
                            :class="{
                              'teal--text text--accent-4': row.completed,
                              'red--text text--lighten-1': row.pending,
                            }"
                          >
                            <v-icon
                              :class="{
                                'teal--text text--accent-4': row.completed,
                                'red--text text--lighten-1': row.pending,
                              }"
                              class="mr-2"
                            >
                              <template v-if="row.completed"
                                >mdi-check-circle-outline</template
                              >
                              <template v-else
                                >mdi-close-circle-outline</template
                              > </v-icon
                            >{{ row.text }}
                          </p>
                        </template>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </DetailTemplate>
  </div>
  <!--end::Calendar-->
</template>

<style>
.v-calendar-weekly__day.v-present {
  background: #ffd39e;
}
.content-px-0 .v-toolbar__content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";

export default {
  mixins: [CommonMixin],
  name: "calendar",
  components: {
    DetailTemplate,
  },
  data: () => ({
    eventLoading: true,
    task_type: "all",
    focus: "",
    type: "month",
    events: [],
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    userList: [],
    users: [],
    selectedElement: null,
    selectedOpen: false,
    timeout: null,
    timeoutLimit: 500,
  }),
  mounted() {
    const _this = this;
    if (_this.$refs["calendar"]) {
      _this.$refs["calendar"].checkChange();
    }
    _this.getCalendarEvents();
    _this.$store.dispatch(SET_BREADCRUMB, [{ title: "Calendar" }]);
  },
  methods: {
    filterEvents() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        _this.getCalendarEvents();
      }, _this.timeoutLimit);
    },
    getCalendarEvents() {
      const _this = this;
      _this.eventLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "calendar/task-events",
          data: {
            type: _this.task_type,
            users: _this.users,
          },
        })
        .then(({ data }) => {
          _this.events = [...data.tasks, ...data.meetings];
          _this.userList = data.users;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.eventLoading = false;
        });
    },
    allowDrop(event) {
      event.preventDefault();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>
